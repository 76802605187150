import React from "react";
import { graphql } from "gatsby";

import { Container } from "react-bootstrap";

import Layout from "../components/common/Layout";
import Pagination from "../components/common/Pagination";
import { ArrowDownBig } from "../helpers/Icons";

import { nextMonday } from "../helpers";
import CategoriesList from "../components/elements/CategoriesList";
import ProductsList from "../components/blocks/ProductsList";

const AllProducts = ({ data, pageContext }) => {
  const products = data.allPrismicProduct.nodes;

  return (
    <Layout>
      <Container className="mb-5">
        <header className="pt-5 pb-5 pb-md-6 text-center">
          <h1 className="h4">Μενού εβδομάδας, {nextMonday()}</h1>
          <ArrowDownBig size={58} />
        </header>
        {products?.length > 0 && (
          <div className="mb-4 mb-md-3 text-end">
            <CategoriesList />
          </div>
        )}
        <ProductsList list={products} pageContext={pageContext} />
        <Pagination
          pageInfo={data.allPrismicProduct.pageInfo}
          base={pageContext.base}
        />
      </Container>
    </Layout>
  );
};

// We use uid to filter products with category field, if we don't pass a uid in
// createPage function the filter will not apply and will get all products.
export const allProductsQuery = graphql`
  query allProductsQuery($limit: Int!, $skip: Int!, $uid: String) {
    allPrismicProduct(
      filter: {
        data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...prismicCardProductFragment
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;

export default AllProducts;
