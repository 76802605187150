import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Nav } from "react-bootstrap";

import { useShop } from "../../context/ShopContext";
import Anchor from "./Anchor";
import { Close } from "../../helpers/Icons";
import { isBrowser } from "../../helpers/checkEnvironment";

const CategoriesList = () => {
  const q = useStaticQuery(graphql`
    {
      prismicShop {
        ...categoriesListFragment
      }
    }
  `);
  const categories = q?.prismicShop?.data?.categories_menu;
  if (categories?.length < 1) return null;

  const { allProducts } = useShop();

  const content = categories.map((single) => {
    const { data, id, url } = single.category.document;

    const isActive = isBrowser ? window.location?.pathname === url : false;

    return (
      <Nav.Item key={id}>
        <Nav.Link
          as={Anchor}
          href={isActive ? allProducts : url}
          className={`link-underline${isActive ? " active" : ""}`}
        >
          {data.title}
          {isActive && (
            <span className="link-remove">
              <Close size={12} color="var(--bs-danger)" />
            </span>
          )}
        </Nav.Link>
      </Nav.Item>
    );
  });

  return (
    <Nav as="nav" className="categories-list justify-content-end">
      {content}
    </Nav>
  );
};

export default CategoriesList;
