import React from "react";

import { Col, Container, Row, Button } from "react-bootstrap";

import CardProduct from "../elements/CardProduct";
import Anchor from "../elements/Anchor";
import { ForkKnifeCircle } from "../../helpers/Icons";

const EmptyState = ({ pageContext }) => (
  <div className="py-md-5 text-center">
    <ForkKnifeCircle size={80} fill="var(--bs-gray-400)" />
    <h2 className="mt-3">Ουπς! Το φαγητό τελείωσε.</h2>
    <p className="text-muted mb-4">
      Δυστυχώς ο κατάλογος
      {pageContext?.title && ` "${pageContext.title}"`} είναι άδειος, δες
      περισσότερα
      <br />
      στην αρχική σελίδα ή επικοινώνησε μαζί μας.
    </p>
    <Button as={Anchor} className="m-2" href="/">
      Αρχική σελίδα
    </Button>
    <Button as={Anchor} className="m-2" href="/pages/contact-us">
      Επικοινωνία
    </Button>
  </div>
);

const ProductsList = ({ list, showPrice, compact, pageContext }) => {
  const products = list?.map((item) => (
    <Col md={6} lg={4} className="mb-5" key={item.uid}>
      <CardProduct product={item} showPrice={showPrice} compact={compact} />
    </Col>
  ));

  return (
    <Container fluid>
      {list?.length > 0 ? (
        <Row>{products}</Row>
      ) : (
        <EmptyState pageContext={pageContext} />
      )}
    </Container>
  );
};

export default ProductsList;
