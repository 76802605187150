import React from "react";

import Anchor from "../elements/Anchor";
import { ArrowLeft, ArrowRight } from "../../helpers/Icons";

const Pagination = ({ pageInfo, base = "/" }) => {
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = pageInfo;

  return (
    pageCount > 1 && (
      <nav role="navigation" aria-label="Pagination navigation">
        <ul className="pagination justify-content-center my-5">
          {/* --- Previous button --- */}
          <li className={`page-item${!hasPreviousPage ? " disabled" : ""}`}>
            {hasPreviousPage ? (
              <Anchor
                href={
                  currentPage - 1 === 1
                    ? base
                    : `${base}page/${currentPage - 1}`
                }
                className="page-link"
                rel="previous"
                aria-label="Navigate to previous page"
              >
                <ArrowLeft size="22" />
              </Anchor>
            ) : (
              <span className="page-link" aria-disabled="true">
                <ArrowLeft size="22" />
              </span>
            )}
          </li>

          {/* --- Pages links --- */}
          {Array.from({ length: pageCount }, (_, index) => {
            const active = index + 1 === currentPage;

            return (
              <li
                className={`page-item${active ? " active" : ""}`}
                aria-current={active ? "page" : "false"}
                key={index}
              >
                {active ? (
                  <span className="page-link" aria-label="Current page">
                    {index + 1}
                  </span>
                ) : (
                  <Anchor
                    href={index === 0 ? base : `${base}page/${index + 1}`}
                    className="page-link"
                    aria-label={`Navigate to page ${index + 1}`}
                  >
                    {index + 1}
                  </Anchor>
                )}
              </li>
            );
          })}

          {/* --- Next button --- */}
          <li className={`page-item${!hasNextPage ? " disabled" : ""}`}>
            {hasNextPage ? (
              <Anchor
                href={`${base}page/${currentPage + 1}`}
                className="page-link"
                rel="next"
                aria-label="Navigate to next page"
              >
                <ArrowRight size="22" />
              </Anchor>
            ) : (
              <span className="page-link" aria-disabled="true">
                <ArrowRight size="22" />
              </span>
            )}
          </li>
        </ul>
      </nav>
    )
  );
};

export default Pagination;
